{
  "-": "-",
  "hello world": "olá mundo",

  "Value is required": "O campo é obrigatório",
  "Value is not a valid email address": "O valor inserido não corresponde a um endereço de e-mail válido.",
  "The value must be equal to the Email value": "O valor inserido deve ser igual ao valor inserido no campo E-mail",
  "The value must be equal to the Password value": "O valor inserido deve ser igual ao valor inserido no campo Senha",
  "This field should be at least 8 long": "O valor inserido deve conter pelo menos 8 caracteres",
  "This field should be at least 8 characters long": "O valor inserido deve conter pelo menos 8 caracteres",
  "Nombre": "Nome",
  "Email": "E-mail",
  "Confirmar email": "Confirmar e-mail",
  "Contraseña": "Senha",
  "Confirmar contraseña": "Confirmar senha",
  "Creá tu cuenta": "Criar sua conta",
  "Ya tengo cuenta": "Já tenho uma conta",
  "Registrarme": "Registre-me",

  "login.error": "Não foi possível fazer login. Verifique seu e-mail e senha e tente novamente.",

  "order_status_open": "Aberto",
  "order_status_closed": "Fechado",
  "order_status_cancelled": "Cancelado",
  "order_status_paid": "Confirmado",
  "order_status_pending": "Em processo",
  "order_status_abandoned": "Abandonado",
  "order_status_authorized": "Autorizado",
  "order_status_refunded": "Reembolsado",
  "order_status_voided": "Rejeitado",
  "order_status_unpacked": "Não embalado",
  "order_status_packed": "Embalado",
  "order_status_shipped": "Enviado",
  "order_status_unshipped": "Não embalado",
  "order_status_ship_unpacked": "Não embalado",
  "order_status_ship_packed": "Embalado",
  "order_status_ship_shipped": "Enviado",
  "order_status_ship_unshipped": "Não embalado",
  "order_status_pickup_unpacked": "Não embalado",
  "order_status_pickup_packed": "Embalado",
  "order_status_pickup_shipped": "Retirado",
  "order_status_pickup_unshipped": "Não retirado",

  "custom_status": "Status personalizado",
  
  "Locale": "Local",

  "month": "Mês",
  "cardNumber is empty.": "O número do cartão está vazio.",
  "cardNumber should be a number.": "O número do cartão deve ser um número válido.",
  "cardNumber should be of length between '8' and '19'.": "O número do cartão deve conter entre 8 e 19 caracteres.",
  "expirationMonth is empty.": "O mês de expiração está vazio.",
  "expirationMonth should be a number.": "O mês de validade deve ser um número válido.",
  "expirationMonth should be a value from 1 to 12.": "O mês de vencimento deve ser um número entre 1 e 12.",
  "expirationYear is empty.": "O ano de expiração está vazio.",
  "expirationYear should be of length '2'.": "O ano de validade deve conter exatamente 2 caracteres.",
  "expirationYear should be a number.": "O ano de vencimento deve ser um número válido.",
  "expirationYear value should be greater or equal than 2023.": "O ano de expiração deve ser maior ou igual a 2023.",
  "securityCode is empty.": "O código de segurança está vazio.",
  "securityCode should be of length '3' or '4'.": "O código de segurança deve conter entre 3 e 4 caracteres.",
  "securityCode should be a number.": "O código de segurança deve ser um número válido.",

  "approved": "Aprovado",
  "accredited": "Acreditado",
  "in_process": "Em processo",
  "offline_process": "Processamento automático",
  "rejected": "Rejeitado",
  "cc_rejected_high_risk": "Alto risco",

  "customer_category": "Categoria do cliente",
  "product_category": "Categoria do produto",
  "product": "Produto",
  "customer": "Cliente",
  "promotion": "Promoção",
  "plural_customer_category": "Categorias de clientes",
  "plural_product_category": "Categorias de produtos",
  "plural_product": "Produtos",
  "plural_customer": "Clientes",
  "plural_promotion": "Promoções",

  "normal": "Normal",
  "preorder": "Pré-venda",
  "delayed": "Com tempo de fabricação",
  "personalizado": "Personalizado",

  "Básico": "Básico",
  "Profesional": "Profissional",
  "Empresarial": "Empresa",
  "Beyond": "Além",

  "file": "Arquivo",
  "cancel": "Cancelar",
  "pay": "Pagar",
  "pack": "Pacote",
  "ship": "Enviar",
  "hide": "Esconder",
  "show": "Mostrar",
  "delete": "Remover",
  "activate": "Ativar",
  "deactivate": "Desativar",
  "hideInstallments": "Ocultar cotas",
  "showInstallments": "Mostrar cotas"
}
